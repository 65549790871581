<template>
  <div class="dashboard container content">
    <div class="headline"><h1>{{ $t('message.users.header') }}</h1></div>
    <div class="tile is-ancestor">
      <div class="tile is-parent is-4">
        <article class="tile is-child box">
          <p class="title">{{ $t('message.users.add.header') }}</p>
          <b-field :label="$t('message.users.add.firstname')">
              <b-input v-model="newUser.first_name"></b-input>
          </b-field>

           <b-field :label="$t('message.users.add.lastname')">
              <b-input v-model="newUser.last_name"></b-input>
          </b-field>

           <b-field :label="$t('message.users.add.mobile')">
              <b-input v-model="newUser.mobile"></b-input>
          </b-field>
          
          <b-field :label="$t('message.users.add.email')">
              <b-input type="email"
                  value=""
                  v-model="newUser.email"
                  maxlength="30">
              </b-input>
          </b-field>

          <b-field :label="$t('message.users.add.password')">
            <b-input type="password"
                value=""
                v-model="newUser.password"
                password-reveal>
            </b-input>
          </b-field>
        
          <b-field :label="$t('message.users.add.confirm-password')">
            <b-input type="password"
                value=""
                v-model="newUser.c_password"
                password-reveal>
            </b-input>
          </b-field>
            <password
                @input="validateFirstPassword"
                v-model="newUser.password"
                :strength-meter-only="true"
            />
            <b-field
                :label="
                    $t(
                        'message.partner.add.user.confirm-password'
                    )
                "
                :type="passwordFieldType"
                :message="passwordFieldMessage"
            >
                <b-input
                    @blur="validatePassword"
                    type="password"
                    value=""
                    v-model="newUser.c_password"
                    password-reveal
                    :validation-message="
                        $t('message.error.password-mismatch')
                    "
                >
                </b-input>
            </b-field>
          <b-field :label="$t('message.users.add.language')">
            <b-select v-model="newUser.lang">
                <option value="fi">{{ $t('message.users.languages.fi') }}</option>
                <option value="en">{{ $t('message.users.languages.en') }}</option>
            </b-select>
          </b-field>
          <b-field :label="$t('message.users.add.notify')">
            <b-checkbox v-model="newUser.notify"
                :true-value="true"
                :false-value="false">
                {{ checkboxValueToString }}
            </b-checkbox>
          </b-field>

          <b-button
            :label="$t('message.users.add.submit')"
            type="is-primary"
            v-on:click="register"  />
        </article>
     </div>
     <div class="tile is-parent is-8">
        <article class="tile is-child box">
          <p class="title">{{ $t('message.users.list.header') }} </p>
           <b-table
                :data="users">

                <b-table-column field="first_name" :label="$t('message.users.list.first_name')" width="40" sortable v-slot="props">
                    {{props.row.first_name}}
                </b-table-column>

                <b-table-column field="last_name" :label="$t('message.users.list.last_name')" width="40" sortable v-slot="props">
                    {{props.row.last_name}}
                </b-table-column>
                
                <b-table-column field="email" :label="$t('message.users.list.email')" width="40" sortable v-slot="props">
                    {{props.row.email}}
                </b-table-column>

                <b-table-column field="org" cell-class="oikealaita" v-slot="props">
                        <b-button v-if="user.id == props.row.id" style="cursor: not-allowed" disabled type="is-danger" size="is-small"
                            icon-left="delete">
                            Delete
                        </b-button>
                         <b-button v-if="user.id != props.row.id" @click="deleteUser(props.row)" type="is-danger" size="is-small"
                            icon-left="delete">
                            Delete
                        </b-button>
                </b-table-column>
                
          </b-table>
        </article>
     </div>
    </div>    
  </div>
</template>

<script>
import axios from 'axios';
import Password from "vue-password-strength-meter";
export default {
    components: {
        Password,
    },
        data() {
            return {
                validator: {
                    password: true,
                    vat: true,
                    email: true,
                    mobile: true,
                    message: {
                        email: "",
                        mobile: "",
                        password: "",
                        vat: ""
                    }
                },
                company: null,
                suggest_company: [],

                partner: null,
                suggest_partner: [],

                newUser: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    mobile: '',
                    password: '',
                    c_password: '',
                    lang: 'fi',
                    company_id: -1,
                    company_uuid: null,
                    type: 'company', 
                    notify: false, 
                },
            }
        },
        computed: {
            checkboxValueToString(){
                if(this.newUser.notify) {
                    return this.$t('message.users.add.notify-texts.yes');
                }
                return this.$t('message.users.add.notify-texts.no');
            },
            user() {
                return this.$store.getters['getUser']
            },
            users() {
                var partners = this.$store.getters['getPartners']
                var companies = this.$store.getters['getCompanies']
                var users = this.$store.getters['getUsers']
                users.forEach(user => {
                    if (user.company_uuid != null) {
                        var org = companies.find(company => company.uuid == user.company_uuid)
                        if (org != undefined) {
                            user.org = org
                        }
                    }
                    if (user.education_partner_id != null) {
                        var org2 = partners.find(partner => partner.uuid == user.education_partner_uuid)
                        if (org2 != undefined) {
                            user.org = org2
                        }
                    }
                });
                return users
            }, 
            companies() {
                return this.$store.getters['getCompanies']
            }, 
            partners() {
                return this.$store.getters['getPartners']
            },
            passwordFieldType() {
                if(this.validator.password) {
                    return "";
                }
            return "is-danger"
            },
            passwordFieldMessage() {
                if(this.validator.message.password != "") {
                    return this.validator.message.password
                }
                return "";
            },
            vatFieldType() {
                if(this.validator.vat) {
                    return "";
                }
                return "is-danger"
            },
            vatFieldMessage() {
                if(this.validator.message.vat != "") {
                    return this.validator.message.vat
                }
                return "";
            },
            emailFieldType() {
                if(this.validator.email) {
                    return "";
                }
                return "is-danger"
            },
            emailFieldMessage() {
                if(this.validator.message.email != "") {
                    return this.validator.message.email
                }
                return "";
            },
            mobileFieldType() {
                if(this.validator.mobile) {
                    return "";
                }
                return "is-danger"
            },
            mobileFieldMessage() {
                if(this.validator.message.mobile != "") {
                    return this.validator.message.mobile
                }
                return "";
            },
            isValid() {
                if(this.newUser.first_name == "" || this.newUser.last_name == "") {
                    return false;
                }
                if(this.newUser.email == "" || this.newUser.mobile == "") {
                    return false;
                }
                if(this.newUser.password.length < 7 || this.newUser.password != this.newUser.c_password) {
                    return false;
                }
                if(this.newCompany.name == "" || this.newCompany.vat == "") {
                    return false;
                }
                return true;
            }
        },
        methods: {
               validateFirstPassword() {
            if(this.newUser.c_password != "") {
                this.validatePassword();
            }
        },
        validatePassword() {
           if(this.newUser.password == this.newUser.c_password && this.newUser.password.length > 6) {    
                this.validator.password = true;
                this.validator.message.password = "";
            } 
            else {
                this.validator.password = false;
                this.validator.message.password = this.$t("message.error.password-mismatch");
                if(this.newUser.password.length < 7) {
                    this.validator.message.password = this.$t("message.error.password-too-short");
                }
            }
        },
        validateEmail() {
            axios.post('users/validate-email',{ email: this.newUser.email}).then((response) => {
                if(response.data.success) {
                    this.validator.email = true;
                    this.validator.message.email = "";
                } else {
                    this.validator.email = false;
                    this.validator.message.email = this.$t("message.error."+response.data.msg);
                }
            })
        },
        validateMobile() {
            axios.post('users/validate-mobile',{ mobile: this.newUser.mobile}).then((response) => {
                if(response.data.success) {
                    this.validator.mobile = true;
                    this.validator.message.mobile = "";
                } else {
                    this.validator.mobile = false;
                    this.validator.message.mobile =  this.$t("message.error."+response.data.msg);
                }
            })
        },
            deleteUser(data) {
                console.log("deleteUser",data);
            },

            openUserCard(item) {
                console.log(item)
                //this.$router.push('/kayttaja/' + item.id)
            },
            updateSuggestedCompanies(txt) {
                var ff = this.companies.filter(comp => {
                    return (
                        comp.name
                            .toString()
                            .toLowerCase()
                            .indexOf(txt.toLowerCase()) >= 0
                    )
                })
                this.suggest_company = ff
            },
            updateSuggestedPartners(txt) {
                var ff = this.partners.filter(comp => {
                    return (
                        comp.name
                            .toString()
                            .toLowerCase()
                            .indexOf(txt.toLowerCase()) >= 0
                    )
                })
                this.suggest_partner = ff
            },
            register() {
                if (this.user.type == "admin") {
                    console.log(this.company)
                    if (this.company != null) {
                        this.newUser.type = "company"
                        this.newUser.company_uuid = this.company.uuid
                    }
                    console.log(this.partner)
                    if (this.partner != null) {
                        this.newUser.type = "education_partner"
                        this.newUser.education_partner_uuid = this.partner.uuid
                    }
                } else {
                    //console.log("companies", this.companies)
                    if (this.user.type == "company") {
                        let companyUuid = ""
                        this.companies.forEach(comp => {
                            if (comp.id == this.user.company_id) {
                                companyUuid = comp.uuid
                            }
                        });
                        this.newUser.company_id = this.user.company_id
                        this.newUser.company_uuid = companyUuid
                        this.newUser.type = "company"
                        //console.log("new user", this.newUser, this.user)
                    }
                    if (this.user.type == "education_partner") {
                        var partnerUuid = ""
                        this.partners.forEach(partner => {
                            if (partner.id == this.user.partner_id) {
                                partnerUuid = partner.uuid
                            }
                        });
                        this.newUser.type = "education_partner"
                        this.newUser.education_partner_id = this.partner.id
                        this.newUser.education_partner_uuid = partnerUuid
                        //console.log("new user", this.newUser, this.user)
                    }
                }
            
                this.$store.dispatch("createUser", this.newUser).then((response) => {
                    var status = response.status
                    if (status >= 200 && status < 300) {
                        this.toastSuccess(this.$t('message.users.add.toaster.user.created'))
                        this.$store.dispatch("fetchUsers");
                    } else {
                        this.toastFailure(this.$t('message.users.add.toaster.user.creationError'));
                    }
                }).catch((error) => {
                    this.ERROR(error);
                    this.toastFailure(this.$t('message.users.add.toaster.user.creationError'));
                });
            }
        },
        mounted() {
            this.$store.dispatch("fetchPartners");
            this.$store.dispatch("fetchCompanies");
            this.$store.dispatch("fetchUsers");
        }
    }
</script>

<style>
    .oikealaita {
        text-align: right !important;
    }
</style>

